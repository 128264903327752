html{
  background: whitesmoke;
}

.App {
  display: grid;
  margin: 0 auto;
  background: whitesmoke;

}

.GridContainer{
  display: grid;
  justify-content: flex-start;
}


.AlignTop{
  align-self: flex-start;
  
}
h1, p, h2{
 margin: 0;
}

input{
  height: 25px;
  padding: 5px;
  
}

ul{
  list-style: none;
  padding: 0;
}



li ::-webkit-scrollbar {
  display: none;
}



.FilterWebsite-Container>ul::-webkit-scrollbar {
  display: none;
}
.LandingPage-Container{
  display: grid;
  /* background: linear-gradient(190deg, black, #0298DC); */
  background: linear-gradient(190deg, whitesmoke, #002F61);
  /* background: linear-gradient(140deg, #2727aa, #f39e4e); */


}



@media screen and (min-width: 1100px) {
  /* .LandingPage-Container{
  background: linear-gradient(190deg, whitesmoke, #002F61);

  } */

/* width */
.cardItems ::-webkit-scrollbar {
  width: 20px;
  height: 5px;
}

.devTools-container ::-webkit-scrollbar {
  width: 20px;

}

/* Track */
.cardItems ::-webkit-scrollbar-track {
  box-shadow: inset 1px -7px 7px rgba(211, 211, 211, 0.549);
  /* border-radius: 10px; */
}

/* Handle */
.cardItems ::-webkit-scrollbar-thumb {
  border-radius: 30px;
  width: 40px;
}

/* Handle on hover */
.cardItems ::-webkit-scrollbar-thumb:hover {
  background: rgb(206, 206, 206);
}


}


.columns3{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.mainNav-container{
  height: 40px;
  width: 100vw;
  position: relative;
  bottom: 0px;
  margin: 0;
  z-index: 3;
  background: none;
  height: 40px;
}

.mainNav-container nav {
  height: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  font: 1.4em sans-serif;
  margin: 0 auto;
  white-space: nowrap;
  align-items: center;

}

.mainNav-container a{
  padding-top: 5px;
  cursor: pointer;
  
}



.tabIcon{
  width: 90%;
  height: 40px;
  background: #0080BF;
  grid-row: 1/2;
  grid-column: 1/2;
  font: .9em sans-serif;
  text-decoration: none;
  /* color: white; */
  border-radius: 10px 10px 0 0  ;
  box-shadow: 0 0 5px;
  cursor: pointer;
  text-align: center;
  justify-self: center;
}



#projectsTab{
  background: none;
  grid-row: 1/2;
  grid-column: 1/2;
}



#aboutMeTab{
  background: none;
  grid-row: 1/2;
  grid-column: 2/3;
}



/* .homePage-container{
  grid-row: 1/2;
  grid-column: 1/2;
} */


/* .aboutMePage-container{
  grid-row: 1/2;
  grid-column: 1/2;
} */





/* Projects page----------------------------------------- */

.projectsPage-container{
  height: auto;
  position: relative;
  display: grid;
  /* background: linear-gradient(to bottom, #00aecd, #136DC0 45%); */
  /* box-shadow:  0 -10px 10px rgba(0, 0, 0, 0.104); */

}

.projectsPage-container>h1{
  color: black;
  background-color: white;
  font: 1.5em sans-serif;
  justify-self: center;
  margin-bottom: 20px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.373);
  padding: 20px;
  border-radius: 7px ;
  /* border: solid #136DC0 4px; */
  margin-top: 20px;
  /* color: linear-gradient(to bottom, #00aecd, #136DC0 45%); */
  
}

.projectsPage-container>h2{
  font: 1.5em sans-serif;
  justify-self: center;
  padding: 20px 60px;
}


.projectsPage-container>header{
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

}

.projectsPage-container>header>h1{
  font: 1.5em sans-serif;
  color: black;
  /* color: white; */

}

.projectsPage-container>header>img{
  height: 45px;
  width: auto;
}




.projectsPage-container header picture img {
  width: 100px;
  justify-self: left;
  border: 4px solid white;
  border-radius: 70px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.226);

}

.slogan-container{
  font-size: 1.4em;
  align-self: center;

}

.projectsPage-container>main{
  display: grid;
  align-content: center;

}


.projectsPage-container>main>picture {
  height: auto;
  grid-row: 1/2;
  grid-column: 1/2;
}



.projectsPage-container>main>img{
  width: auto;
  height: 650px;
}

/* card items project container-------------------------------------------------- */

.cardItems {
  /* grid-row: 1/2;
  grid-column: 1/2; */
  display: grid;
  margin: 0;
  padding: 0;
  height: auto;
  align-self: center;
  /* margin-top: -150px; */
  /* box-shadow: -5px 10px 13px rgba(0, 0, 0, 0.11); */
}

.cardItems>h1{
  font: 2em sans-serif;
  padding: 20px;
  justify-self: center;
}

.cardItems ul {
  display: flex;
  float: left;
  overflow-x: scroll;
  overflow-y: hidden;
  /* scrollbar-width: none; */
  margin-top: 30px;
}

.cardItems ul li:first-child{
  margin-left: 20px;
}




.cardItems>ul>li {
  display: grid;
  margin-left: 30px;
  border-radius: 15px;
  margin-top: 10px;
  
}   
  

.cardItems>ul>li>img{
  /* grid-row: 2/3;
  grid-column: 1/2; */
  /* background-image: cover; */
  border-radius: 12px 12px 0 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.74);
  justify-content: center;
  height: 250px;
  border: solid black 2px;
  border-radius: 20px;
  background-color: white;

  
}

@media screen and (min-width: 1200px){

  .cardItems ul li {
    margin-left: 50px;
    padding-bottom: 7px;
  }

}




.cardItems ul li:last-child {
  background: none;
  box-shadow: none;
  width: 7px;
}

.cardItems>ul>li>aside{
  display: grid;
  justify-items: center;
  align-items: center;
}



.cardItems ul li h2 {
  font: 1.3em sans-serif;
  text-align: center;
  align-self: center;
  padding: 10px;

}








.cardItems footer {
  column-gap: 10px;
  align-self: flex-end;
  /* box-shadow: 0px -7px 5px rgba(0, 0, 0, 0.137); */
  padding: 10px;
}

.cardItems footer a {
  cursor: pointer;
  text-decoration: underline;
  width: 60px;
  padding: 5px;
}
  
.project1-container footer button {
  background: #52B1A2;
}

.project1-container footer a {
  color: #52B1A2;
  padding-left: 5px;
  align-self: center;
  
}

.project1-container>aside{
  align-self: center;
}


.project2-container footer button {
  background: #0080BF;
}

.project2-container footer a {
  color: #0080BF;
  padding-left: 5px;
  align-self: center;
  
}



.project3-container footer button {
  background:#B936D2;
}

.project3-container footer a {
  color:#B936D2;
  padding-left: 5px;
  align-self: center;
  
}

.project4-container footer button {
  background: #002F61;
}

.project4-container footer a {
  color: #002F61;
  padding-left: 5px;
  align-self: center;
  
}

.project5-container footer button {
  background: #21523F;
}

.project5-container footer a {
  color: #21523F;
  padding-left: 5px;
  align-self: center;
  
}


.project6-container footer button {
  background: #469F67;
}

.project6-container footer a {
  color: #469F67;
  padding-left: 5px;
  align-self: center;
  
}





.tools-container{
  grid-row: 2/3;
  grid-column: 1/2;
  background: white;
  display: grid;
  z-index: 2;
  height: 95%;
  font: 1.1em sans-serif;
  color: black;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
  align-self: flex-end;
}


.tools-container aside {
  height: 40px;
  display: flex;
  justify-self: center;
  column-gap: 15px;
}

.tools-container aside button{
  padding: 10px;
  /* background: linear-gradient(to right, #136DC0, #00aecd); */
  background: rgb(236, 236, 236);
  border: solid 0.5px rgb(145, 145, 145);
  /* color: white; */
  border-radius: 3px;
  cursor: pointer;

}


#icon-container {
  display: grid;
  grid-template-columns: 40px 3fr;
  padding: 20px 20px 0 20px;
  align-items: center;
  align-self: start;
  z-index: 0;

}

#icon-container img {
  height: 30px;
  width: 30px;
}




.mainNav-container nav{
  display: grid;
  align-self: flex-end;
  text-align: center;
  column-gap: 3px;
  margin: 0 5px;

}

/* .mainNav-container :first-child :first-of-type {
  border-bottom: solid 5px #007fbf;

} */


/* like count */

.likeProjects-container{
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.2);
  justify-items: center;
  align-items: center;

}

#heartIcon {
  height: 15px;
  width: 15px;
}
.likeProjects-container aside{
  display: grid;
  grid-template-columns: 1fr 2fr ;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  height: 20px;
  width: 50px;
  border-radius: 5px;
  color: white;
  padding: 5px;

}

.likeProjects-container a{
  border: none;
  background: none;
  font: 1.1em sans-serif;
  text-decoration: underline;
  cursor: pointer;
  
}


/* tablet displays */
@media screen and (min-width: 700px) {
  .App {
    width: auto;
  }

  .slogan-container{
    display: grid;
    column-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-self: right;
    font-size: 1.8em;


  }
 


  
  .mainNav-container{
    width: 400px;
    right: 0px;
    margin: 0 15px;

  }



}


/* footer styles */

.myStoryFooter-container{
  background: #2862AF;
  width: auto;
  position: sticky;
  top: 0;
  left: 0;
  /* z-index: 1; */


}

.myStoryFooter-container>footer{
  display: grid;
  padding-bottom: 0;
  row-gap: 10px;
  max-width: 90em;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-end;
 


}

@media screen and (min-width: 1100px){
.myStoryFooter-container>footer{
  display: flex;
  column-gap: 10px;
  
}

}

.menuIcon-container{
  display: grid;
  row-gap: 5px;
  justify-content: center;
  cursor: pointer;
  /* background: red; */
  /* transform: rotate(20deg); */
}

.menuIcon{
  width: 43px;
  height: 5px;
  background: white;
  border-radius: 10px;
}



.footerNav {
  position: static;
  display: grid;
  margin: 0;
  padding: 0;
  height: auto;
  /* box-shadow: -5px 10px 13px rgba(0, 0, 0, 0.11); */
  align-content: flex-end;

 

}



.footerNav>ul {
  display: flex;
  float: left;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  padding: 0px;
  margin: 0px;
  align-self: flex-end;
  padding-top: 15px; 
}



.footerNav ::-webkit-scrollbar {
  display: none;
}




.footerNav>ul>li {
  font: 1.2em sans-serif;
  display: grid;
  grid-template-columns: 200px;
  margin-left: 30px;
  border-radius: 15px;
  margin-top: 10px;
  align-self: flex-end;


  
}   



.myStoryFooter-container>footer button{
  padding: 10px;
  border-radius: 20px 20px 0 0;
  background: whitesmoke;
  border: solid 1px whitesmoke;
  color: #2862AF;
  font: 1em sans-serif;
  max-width: 200px;
  cursor: pointer;
  box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.234);
  outline: none;
}



.linkCard-container{
  display: grid;
  background: whitesmoke;
  justify-self: center;
  margin-top: 40px;

}



.linkCard-container header{
  display: grid;
  max-width: 400px;

}

  .portfolio-img{
    grid-row: 1/2;
    grid-column: 1/2;
  align-self: flex-start;
  justify-self: center;

  } 

  .portfolio-img img{
    max-width: 400px;
    height: 100%;
  }



.socialIcon-container{
  display: grid;
  row-gap: 10px;
  grid-row: 1/2;
  grid-column: 1/2;
  height: 400px;
  width: 70px;
  align-self: flex-start;
  padding-left: 40px;
  padding-top: 40px;
}

.socialIcon-container img{
  width: 50px;
  height: 50px;
}


.linkCard-container>main{
  display: grid;
  row-gap: 10px;
  align-content: flex-start;
  justify-self: right;
  background: #0080BF;
  max-width: 400px;
  height: 550px;
  color: white;
  padding: 20px;
 font: 1em sans-serif;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.446);
}

.linkCard-container h1 {
  font-weight: normal;

}

.linkCard-container p{
  line-height: 25px;
}

.linkCard-container h3{
   margin: 0;
  font-weight: normal;

}

.linkCard-container button{
  border: solid 2px white;
  border-radius: 10px;
  background: #0080BF;
  color: white;
  width: 100px;
  font: 1em sans-serif;
  height: 40px;
}

.tabContent-container section{
  height: 90vh;
  /* last */
}



/* My header styles---------------------------------------------------- */

.myHeader-container{
  display: grid;
  /* background: linear-gradient(180deg, #00aecd, #136DC0); */
  /* background: linear-gradient(140deg, #2727aa, #f39e4e); */

  justify-items: center;
  font: 1em sans-serif;
  padding-top: 20px;
  margin-top: -4px;
  /* animation: liquidBackground 5s infinite alternate; */
}


/* @keyframes liquidBackground {
  0% {
      background-position: 0% 0%;
  }
  100% {
      background-position: 100% 100%;
  }
} */

.myHeader-container>header>footer{
  display: grid;
  justify-content: center;
  justify-items: center;
  row-gap: 20px;
  padding-bottom: 10px;
}

.myHeader-container>header>footer>h2{
  color: white;
  font: 2.5em sans-serif;
  padding: 30px;
}

.myHeader-container>header>footer>p{
  font: 1.5em sans-serif;
  color: white;
}


.myHeader-container>header button{
  border: none;
  padding: 10px;
  border-radius: 3px;
  height: 40px;
  font: 1.1em sans-serif;
  color: #0080BF;
  margin-left: 5px;

}


.Contact-Container{
  display: grid;
  grid-template-rows: 70px ;
  padding: 10px;

}

.Contact-Container>header{
  display: grid;

}

.Contact-Container>header button{
  height: 80px;
  padding-top: 20px;
  cursor: pointer;
  margin-left: -40px;
  width: 60px;
  color: grey;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  border: none;
  background-color: whitesmoke;
}

.Contact-Container>main{
  display: flex;
  column-gap: 20px;
  justify-self: center;
  padding-top: 5px;

}

.Contact-Container>main a{
  display: grid;
  text-decoration: none;
  justify-items: center;
  row-gap: 3px;
  padding: 5px;
  width: 45px;

}

.Contact-Container>main a button{
  cursor: pointer;
  color: #0080BF;
  font: .8em sans-serif;
  border: none;
  background-color: white;

}


.myHeader-container aside{
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  justify-self: center;
  margin: 0 auto;
  width: 103%;
  max-width: 45em;
  z-index: 20;
  background: white;
  justify-items: center;
  justify-content: center;
  padding: 40px 0;
  box-shadow: 0 7px 17px rgba(0, 0, 0, 0.364) ;

  }

.myHeader-container aside> button{
  background: #2862AF;
  margin: 0 auto;
  color: white;
  border: none;
  height: 30px;
  padding: 0 20px;
  font: 1em sans-serif;
  cursor: pointer;
}

  .myHeader-container aside header{
    display: grid;
    justify-items: center;
    align-items: center;
    padding-top: 10px;
  }


.myHeader-container aside header h4{
  padding: 10px 20px;
  /* padding-top: 20px; */
  font: 1.3em sans-serif;
  color: black;
  /* border: solid 2px white; */

}


.myHeader-container aside form{
  display: grid;
  grid-template-columns: 1fr 100px;
  background: white;
  row-gap: 10px;
  padding:  20px ;
  column-gap: 5px;
}


.myHeader-container aside form input,
.myHeader-container aside form textarea{
  font: 1em sans-serif;

}

.myHeader-container aside form textarea{
  display: none;
  
}

.myHeader-container aside form button{
  background: #2862AF;
  color: white;
  border: solid white 2px;
  font: 1em sans-serif;
  width: 100px;
  padding: 5px;
  border-radius: 15px;
  outline: none;
  border: solid 1px white ;

}




button:focus{outline: none;}

.myHeader-container aside form p{
  color: #2863afc9;
  text-decoration: underline;
  cursor: pointer;
  width: 120px;
}




.myHeader-container>main{
  background: whitesmoke;
  width: 100%;
  justify-content: center;
  justify-items: center;
}

h1, h3{
  margin: 0;
  cursor: default;
  
}

.name-container{
  display: flex;
  column-gap: 15px;
  row-gap: 5px;
  /* width: 99vw; */
  /* justify-content: center; */
  margin: 0 auto;
  padding: 30px;
  color: whitesmoke;


}

.photo-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 10px;
  width: 230px;

}


.photo-container a {
  color: whitesmoke;
  font: 1em sans-serif;

}




.name-container header{
  display: grid;
  align-self: center;
  font: 1em sans-serif;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  align-items: center;
  max-width: 350px;


}



.name-container h1{
  font: 1.8em sans-serif;
  color: whitesmoke;

}


.name-container h2{
  font: 1.19em sans-serif;
  color: whitesmoke;

}


.name-container h3{
  font: 1.8em sans-serif;
  color: whitesmoke;

}

.name-container p{
  font: 1.1em sans-serif;
  color: whitesmoke;
 font-family: Verdana, Geneva, Tahoma, sans-serif;

  
}






.name-container img{
  border: solid 2px whitesmoke;
  border-radius: 100px;
  height: 120px;
  align-self: center;
}


.name-container button{
  background: #7b99c0;
  border: solid 1px whitesmoke;
  height: 30px;
  border-radius: 10px;
  color: whitesmoke;
  width: 100px;
  font: 1em sans-serif;
  justify-self: left;
  cursor: pointer;
}

.mySkills-container{
  display: grid;
  color: white;
  padding-bottom: 50px;
  


}

.mySkills-container ul {
  display: flex;
  column-gap: 10px;
  overflow: scroll;
  padding: 20px 10px;
  /* border: solid 1px white; */
  background: rgba(255, 255, 255, 0.24);
  width: auto;
  border-radius: 10px;


}



.mySkills-container>h1{
  padding: 10px 40px;
  color: white;
  text-align: center;
  font: 1.5em sans-serif;

}

.mySkills-container>p{
  font: 1.5em sans-serif;
  padding: 20px 40px;
  color: white;
  text-align: center;
}


.mySkills-container ul li {
  display: grid;
  /* border: solid 1px rgba(255, 255, 255, 0.658); */
  height: 40px;
  justify-items: center;
  align-items: center;
  min-width: 170px;
  padding: 0 10px;
  background: #2862AF;
  border-radius: 7px;
  font: 1em sans-serif;
  cursor: default;
  color: whitesmoke;

}

.mySkills-container button{
  display: none;
}


.mySkills-container ::-webkit-scrollbar {
  display: none;
}


.myFocus-container{
  display: grid;
  max-width: 65em;
  background: none;
  margin: 0 auto;
  padding: 20px;
  height: auto;
  margin-top: -200px;
  justify-content: center;

}


.myFocus-container>button{
  border: none;
  background: whitesmoke;
  font: 1em sans-serif;
  padding: 10px;
  color: #2862AF;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
}

.myFocus-container ul{
  display: grid;
  row-gap: 10px;
  padding: 10px;
  height: 200px;
  overflow: hidden;
  

}



.myFocus-container h1{
  color: whitesmoke;
  font: 1.7em cursive;

}

.myFocus-container ul li{
  display: grid;
  height: 20px;
  grid-template-rows: 35px;
  border-radius: 7px;
  background: white;
  padding: 20px;
  overflow-y: hidden;
  width: 270px;
  align-items: flex-start;
  max-width: 350px;
  box-shadow:  0 0 3px rgba(128, 128, 128, 0.562);
  /* z-index: 1; */

}




.myFocus-container ul li h2 {
  color: #2862AF;
  cursor: pointer;
  font: 1.7em monospace;
}





.myFocus-container ul li p {
  font: 1em;
  line-height: 25px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
}

.devTools-container{
  display: grid;
  /* background: linear-gradient(180deg, #2727aa, #f39e4e); */
  justify-content: center;
  row-gap: 20px;
  width: auto;
  /* height: 100vh */

}

.devTools-container>section{
  display: grid;
  align-items: center;
  justify-content: center;
}
.devTools-container>h2{
  color: white;
  font: 2em sans-serif;
  justify-self: center;
  align-self: center;
  padding-top: 20px;
  margin-top: 40px;
  text-align: center;

}

.blueLine{
  display: none;
  grid-column: 1/2;
  grid-row: 1/2;
  height: 10px;
  width: auto;
  background: #2862AF;
}

.devTools-container>section>ul>li>h2{
  color: #2862AF;
  text-align: center;
  font: 2em sans-serif;
}

.devTools-container ul{
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
  float: left;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 98vw;
  column-gap: 5px; 
}


.devTools-container ul li{
  display: grid;
  background: white;
  min-height: 50px;
  width: 250px;
  padding: 20px;
  justify-self: center;
  box-shadow:  0 0 5px rgba(128, 128, 128, 0.562);
  text-align: center;
  align-items: center;
  border-radius: 10px;
  margin: 10px;
  /* z-index: 1; */
}

.devTools-container ul li h2{
  font: 1.5em sans-serif;
  margin: 0;

}


.devTools-container section:first-of-type ul li:last-of-type{
  background: none;
  box-shadow: none;
}





#frameworks{
  width: 250px;

}


.CardFooter-Container>a>button{
  height: 35px;
  border-radius: 7px;
  cursor: pointer;
  width: 90%;
  border: none;
  background: none;
  color: #0080BF;
  text-decoration: underline;
}

.FilterWebsite-Container{
  width: 100%;
  background-color: whitesmoke;
  /* position: sticky;
  top: 0; */
  border-radius: 20px 20px 0 0;


}


.FilterWebsite-Container>ul{
  display: flex;
  column-gap: 10px;
  overflow: scroll;
  width: 95vw;
  padding-left: 20px;
}

.FilterBTN{
  background-color: whitesmoke;
  color: #0080BF;
  width: 150px;
  height: 40px;
  /* border: solid 1px #136DC0; */
  border: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.266);
  border-radius: 7px;
  margin: 5px;
}

.MainBTN{
  font: 1em sans-serif;
  color: white;
  background: linear-gradient(rgb(0, 174, 205), rgb(19, 109, 192) 50%);
  width: 100%;
  height: 50px;
  border: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.266);
  border-radius: 7px;
  margin: 5px;
  cursor: pointer;

}


.CustomWebsite-Container{
  display: grid;
  justify-self: center;
  max-width: 80em;
  justify-items: center;
  row-gap: 10px;
}

.CustomWebsite-Container>picture>img{
  width: 90%;
  
}

.CustomWebsite-Container>section{
  display: grid;
  padding: 10px;
  row-gap: 15px;
  font: 1em sans-serif;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.435);

}

.CustomWebsite-Container>section>h1{
  font: 2em sans-serif;
}

.CustomWebsite-Container>section>{
  display: grid;
  row-gap: 25px;
}

.CustomWebsite-Container>section>p{
  font: 1.3em sans-serif;
}

.Form-Container{
  display: grid;
  justify-content: center;
  width: 100vw;

}


.footer-container{
  display: grid;
  /* background-color: #f39e4e;   */
  justify-content: center;
  padding: 20px;
  height: 50px;
  align-items: center;
}

.footer-container>a{
  font: 1.3em sans-serif;
  color: white;
  text-decoration: none;
}


.AboutFree-Container{
  display: grid;
  padding: 20px;
}






@media screen and (min-width: 500px){



      
  .myFocus-container ul{
    display: grid;
    row-gap: 10px;
    height: auto;
    overflow: none;
    padding: 10px;
    
  
  }
  
}


@media screen and (min-width: 750px){
  

  .mySkills-container ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 3px;
    column-gap: 35px;
    padding: 35px;
    width: 62em;
  }

  
  .mySkills-container ul li{
    min-width: 120px;
    font-weight: normal;
  }

  
  .myFocus-container ul{
    grid-template-columns: repeat(3,1fr);
    column-gap: 20px;
  }
  .myFocus-container>button{
    text-align: left;
  }

  .myFocus-container ul li{
    width: auto;
  }

  .myFocus-container ul::-webkit-scrollbar{
    display: none;
    width: 0;
  }
  
  /* .myFocus-container ul li:first-of-type{
    grid-column: 1/2;
    grid-row: 1/4;
    
  } */
  
  .mySkills-container h1{
    padding: 0;
  }
  
 
}



@media screen and (max-width: 375px) {
  .myHeader-container{
    display: grid;
  }

  /* .cardItems>ul>li>img{
    height: 500px;
  } */

    
  }




@media screen and (min-width: 500px){


  .devTools-container section:first-of-type ul li:last-of-type{
    background: none;
    box-shadow: none;
    display: none;
  }

  .devTools-container ul {
  justify-content: center;
  column-gap: 30px; 
  }

}

.about-me{
  display: grid;
  /* background-color: red; */
  max-width: 65em;
  justify-self: center;
}


  
@media screen and (min-width: 750px){

  .myHeader-container{
    row-gap: 20px;
    display: grid;
    align-items: center;
    /* height: 75vh; */

  }
  .myHeader-container aside{
    position: absolute;
    padding: 200px 0;

  
    }

    

  .myHeader-container aside form{
    padding:  40px 150px ;

  }

}

@media screen and (min-width: 1100px){

  .myHeader-container>header>footer>h2{
    width: auto;
    padding-top: 40px;
    font: 2.9em sans-serif;
  }

  
  /* .myHeader-container{
    height: 75vh;
  } */

  .Cost-Container{
    grid-template-columns: 1fr 1fr;
    column-gap: 150px;
  }
  #frameworks{
    width: 450px;
  
  }

  
    .linkCard-container{
      grid-template-columns: 1fr 1fr;
      padding-top: 100px;
      margin-top: 0;
    }
  
    .linkCard-container>header{
      grid-row: 1/2;
      grid-column: 2/3;
      align-self: center;
    }
  
    .linkCard-container>main{
      grid-row: 1/2;
      grid-column: 1/2;
      align-self: center;
      padding: 40px;
      text-decoration: none;
      text-align: center;
    }

    
  
  }


  @media screen and (min-width: 1050px){

    .CustomWebsite-Container{
      grid-template-columns: 1fr 2fr 1fr;
      align-items: center;
      column-gap: 10px;
      margin: 40px;
  
    }
      
    
      .name-container>header{
        display: grid;
        column-gap: 10px;
        align-self: center;
        font: 1em sans-serif;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        max-width: none;
      }
  
      .devTools-container ul{
    
        display: flex;
         
      }
  
      .GridContainer{
        justify-content: center;
      }
  
      .name-container{
        justify-content: center;
      }
      
    
    }
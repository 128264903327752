.Services-Container{
    /* background: linear-gradient(140deg, #2727aa, #f39e4e); */
    padding-bottom: 40px;
}

.Services-Container>header>h1{
    font: 2em sans-serif;
}
.ServicesCard {
    display: grid;
    justify-content: center;
    row-gap: 20px;
    align-items: center;


}

.ServicesCard>h1{
    font: 2em sans-serif;
    color: white;
    text-align: left;
    padding: 28px;
    text-shadow: 2px 2px 14px #00000046;
    text-align: center;
}


.ServicesCard>li::-webkit-scrollbar{
    width: 0;
    height: 0;
}

.ServicesCard>li{
    display: grid;
    row-gap: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    align-content: flex-start;
    /* max-width: 35em; */
    overflow: scroll;

}


.ServicesCard>li>main{
    display: grid;
    padding-bottom: 20px;
    align-content: flex-start;
}

.ServicesCard>li>main>h3{
    font: 1em sans-serif;
    color: #0080BF;
}

.ServicesCard>li>header{
    font: 1em sans-serif;
}

.ServicesCard>li>header>h2{
    color: #0080BF;
    font: 1.9em sans-serif;
}

.ServicesCard>li>h2{
    font: 1.5em sans-serif;
    color: #0080BF;
    width: 350px;
    padding: 5px;
    /* cursor: pointer; */

}


.ServiceCardInfo{
    display: grid;
    justify-items: center;
    row-gap: 30px;
}

.ServiceCardInfo ul{
    justify-self: left;
}
.ServiceCardInfo> p{
    font: 1.3em sans-serif;
    color: black;

}

.ServiceCardInfo> img{
    width: 360px;
    height: auto;
}

.PeaceOfMind{
    display: grid;
    padding-top: 40px;
    justify-items: center;
    align-content: center;
}

.PeaceOfMind>h2{
    font: 2em sans-serif;
    color: white;
    align-self: center;
}

.PeaceOfMind>main{
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 40px;
    border-radius: 20px;
    background-color: white;
    row-gap: 50px;
}


.Cost-Container{
    display: grid;
    /* background-color: white; */
    /* max-width: 65em; */
    margin: 0 auto;
    border-radius: 20px;
    padding: 10px;  
    
}

.Cost-Container>aside{
    justify-items: center;
}

.Cost-Container>aside>img{
    width: 150px;
    height: auto;
}

.Cost-Container>aside h1{
    font: 2em sans-serif;
    color: white;
    text-align: left;    
    padding: 28px;
    text-shadow: 2px 2px 14px #00000046;
    text-align: center;
}   


.Cost-Container>header{
    display: grid;
    background-color: white;
    height: 200px;
    width: 200px;
    border-radius: 200px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-self: center;
    
}



.pricing-container{
    display: grid;
    grid-template-columns: 4fr 1fr;
    justify-content: flex-start;
    

}

.pricing-container>li{
    display: grid;
    justify-items: flex-start;
    align-items: flex-end;
}



.ServicesCard>li>h3{
    font: 1em sans-serif;
    color: #0080BF;
}


.BulletPoint{
    list-style-type: circle;
    margin-left: 20px;

}

.AppPricing-Container{
    display: grid;
    row-gap: 10px;
    font: 1em sans-serif;
    
    
}

.AppPricing-Container>section>h3{
   font: 1.3em sans-serif;
   color: black;
}


.AppPricing-Container>section:last-of-type{
    padding-top: 40px;
 }
.MonthlyServicesInfo{
    margin-top: 10px;
}

.MonthlyOptions{
    display: flex;
    width: 100%;
    column-gap: 3px;
}


.MonthlyOptions>button{
    font: 1em sans-serif;
    padding: 10px;
    border: none;
    background-color: white;
    color: rgb(19, 109, 192);
    height: 40px;
    width: 100%;
    cursor: pointer;
    border-bottom: solid 1px rgb(19, 109, 192);
}





@media screen and (min-width: 1100px) {
    .BrakePoint{
        display: none;
    }

    .ServiceCardInfo{
        display: grid;
    }
    .PeaceOfMind{
        justify-content: center;
      
    }

    .Services-Container {
        padding: 100px;
    }

    .Cost-Container{
        padding: 40px;
        height: 80vh;
    }

    .Cost-Container>aside h1{
        font: 3em sans-serif;
        padding: 40px;
        align-self: center;
        margin-top: 150px;
        /* font space*/
        

    }

    .Services-Container>main{
        display: grid;
        /* grid-template-columns: 1fr 1fr 1fr; */
    }
    .ServicesCard>li{
        padding: 30px;
        max-width: 60em;
    }

    .ServiceCardInfo{
        width: 60em;
    }
 
    .PeaceOfMind{
        width: 25em;
    }

 


}